<template>
  <c-box
    flex-grow="1"
    min-width="0"
  >
    <c-flex
      width="100%"
      align-items="center"
      margin-bottom="20px"
      gap="48px"
    >
      <c-button
        margin-top="17.5px"
        variant="ghost"
        display="flex"
        align-items="center"
        gap="1rem"
        @click="$router.back()"
      >
        <c-image
          w="24px"
          h="24px"
          object-fit="cover"
          :src="require('@/assets/icon-chevron-left.svg')"
        />
        <c-text
          font-size="16px"
          color="primary.400"
          font-weight="500"
        >
          Kembali
        </c-text>
      </c-button>

      <BreadcrumbPath
        :paths="[
          {
            label: 'Manajemen Referral',
            href: '/admin/referrals',
          },
          {
            label: isEditPage ? 'Edit Skema Referral' : 'Tambah Skema Referral',
            isCurrent: true
          },
        ]"
      />
    </c-flex>

    <c-box
      width="100%"
      max-width="1270px"
      background-color="#FFF"
      margin-bottom="16px"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding="['16px', '30px 80px']"
      :min-height="['unset', '74vh']"
      display="flex"
      flex-direction="column"
      align-items="center"
    >
      <BaseText
        size-mobile="20px"
        size-desktop="28px"
      >
        {{ isEditPage ? 'Edit Skema AjakSehat' : 'Tambah Skema AjakSehat' }}
      </BaseText>

      <c-box
        as="form"
        margin-top="70px"
        width="100%"
      >
        <BaseText
          size-mobile="18px"
          size-desktop="20px"
          color="primary.400"
        >
          Banner Skema
        </BaseText>
        <BaseDivider />

        <BaseInputPhotos
          :photos="images"
          style="margin-top: 16px"
          :is-invalid="isInvalidField($v.images)"
          :invalid-text="parseErrors('Banner Skema', $v.images)"
          @update:photos="(newValue) => images = newValue"
          @blur="$v.images.$touch"
        />

        <BaseInputSelect
          v-model="status"
          label="Status Skema"
          :options="[
            { value: '1', label: 'Aktif' },
            { value: '0', label: 'Nonaktif' },
          ]"
          placeholder="Masukkan Status Skema"
          is-required
          :is-invalid="isInvalidField($v.status)"
          :invalid-text="parseErrors('Status Skema', $v.status)"
          full-width
          :is-disabled="!!isEditPage"
          @blur="$v.status.$touch"
        />

        <c-box margin="30px 0 16px 0">
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="primary.400"
          >
            Periode Skema
          </BaseText>
          <BaseDivider />
        </c-box>

        <c-flex gap="32px">
          <BaseInputDate
            v-model="startAt"
            label="Mulai Periode kode"
            placeholder="Masukkan Mulai Periode kode"
            full-width
            is-required
            :is-disabled="isEditPage && status === '1'"
            :is-invalid="isInvalidField($v.startAt)"
            :invalid-text="parseErrors('Mulai Periode kode', $v.startAt)"
            @blur="$v.startAt.$touch"
          >
            <template #input-left-addon>
              <inline-svg
                :src="require('@/assets/icons/icon-calendar.svg')"
                height="24px"
                width="24px"
                fill="#888888"
              />
            </template>
          </BaseInputDate>

          <BaseInputDate
            v-model="endAt"
            label="Akhir Periode kode"
            placeholder="Masukkan Akhir Periode kode"
            full-width
            is-required
            :is-invalid="isInvalidField($v.endAt)"
            :invalid-text="parseErrors('Akhir Periode kode', $v.endAt)"
            @blur="$v.endAt.$touch"
          >
            <template #input-left-addon>
              <inline-svg
                :src="require('@/assets/icons/icon-calendar.svg')"
                height="24px"
                width="24px"
                fill="#888888"
              />
            </template>
          </BaseInputDate>
        </c-flex>

        <BaseInputSelect
          v-model="discountType"
          label="Jenis Potongan Kode"
          :options="[
            { value: 'fix', label: 'Fix' },
            { value: 'percent', label: 'Percent' },
          ]"
          placeholder="Pilih Jenis Potongan Kode"
          is-required
          :is-invalid="isInvalidField($v.discountType)"
          :invalid-text="parseErrors('Jenis Potongan Kode', $v.discountType)"
          full-width
          @blur="$v.discountType.$touch"
        />

        <BaseInputText
          v-model="discountValue"
          label="Nilai Potongan Kode"
          placeholder="Masukkan Nilai Potongan Kode"
          full-width
          is-required
          :is-invalid="isInvalidField($v.discountValue)"
          :invalid-text="parseErrors('Nilai Potongan Kode', $v.discountValue)"
          :input-left-addon="discountType === 'fix' ? 'Rp' : null"
          :input-right-addon="discountType === 'percent' ? '%' : null"
          @blur="$v.discountValue.$touch"
        />

        <c-box margin="30px 0 16px 0">
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="primary.400"
          >
            Program Terpilih
          </BaseText>
          <BaseDivider />
        </c-box>

        <BaseInputCheckbox2
          v-model="programs"
          is-required
          template-columns="repeat(2, 1fr)"
          :options="optionPrograms"
          :is-invalid="isInvalidField($v.programs)"
          :invalid-text="parseErrors('Opsi Program', $v.programs)"
          :is-inline="false"
          gap="16px"
          @blur="$v.programs.$touch"
        />

        <c-box margin="30px 0 16px 0">
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="primary.400"
          >
            Ketentuan Poin
          </BaseText>
          <BaseDivider />
        </c-box>

        <BaseInputText
          v-model="poinDivider"
          label="Minimal Nilai Transaksi"
          placeholder="Masukkan Minimal Nilai Transaksi"
          full-width
          is-required
          :is-invalid="isInvalidField($v.poinDivider)"
          :invalid-text="parseErrors('Minimal Nilai Transaksi', $v.poinDivider)"
          @blur="$v.poinDivider.$touch"
        />

        <BaseInputText
          v-model="poinMultiplier"
          label="Poin per Nilai Transaksi"
          placeholder="Masukkan Poin per Nilai Transaksi"
          full-width
          is-required
          :is-invalid="isInvalidField($v.poinMultiplier)"
          :invalid-text="parseErrors('Poin per Nilai Transaksi', $v.poinMultiplier)"
          @blur="$v.poinMultiplier.$touch"
        />

        <c-box margin="30px 0 16px 0">
          <c-flex
            gap="16px"
            align-items="center"
          >
            <BaseText
              size-mobile="18px"
              size-desktop="20px"
              color="primary.400"
            >
              Bonus Poin
            </BaseText>
            <c-checkbox
              v-model="isUseTargets"
              size="lg"
              variant-color="primary"
            />
          </c-flex>
          <BaseDivider />
        </c-box>

        <TestFieldArray v-slot="{ push, remove }">
          <c-box
            v-for="(v, i) in targets"
            :key="i"
            margin-top="16px"
            :_first="{ marginTop: '0px' }"
          >
            <c-flex
              gap="32px"
              align-items="center"
            >
              <BaseInputText
                v-model="v.target"
                :is-disabled="!isUseTargets"
                :label="`Target ${i + 1}`"
                :placeholder="`Masukkan Target ${i + 1}`"
                full-width
                is-required
                :is-invalid="isInvalidField($v.targets.$each[i].target)"
                :invalid-text="parseErrors(`Target ${i + 1}`, $v.targets.$each[i].target)"
                @blur="$v.targets.$each[i].target.$touch"
              />
              <BaseInputText
                v-model="v.bonus"
                :is-disabled="!isUseTargets"
                label="Bonus"
                placeholder="Masukkan Bonus"
                full-width
                is-required
                :is-invalid="isInvalidField($v.targets.$each[i].bonus)"
                :invalid-text="parseErrors('Bonus', $v.targets.$each[i].bonus)"
                @blur="$v.targets.$each[i].bonus.$touch"
              />

              <c-button
                v-if="i !== 0"
                background-color="transparent"
                :disabled="!isUseTargets"
                @click="remove(targets, i)"
              >
                <inline-svg
                  :src="require('@/assets/icons/icon-trash.svg')"
                  height="38px"
                  width="38px"
                  fill="#D32737"
                />
              </c-button>
            </c-flex>
          </c-box>

          <c-flex justify-content="center">
            <BaseButton
              margin="auto"
              mt="28px"
              size="large"
              border-radius="1000px"
              variant="outlined"
              width="70%"
              :disabled="!isUseTargets"
              @click.prevent="push(targets, {
                id: Date.now()?.toString(),
                target: null,
                bonus: null,
              })"
            >
              Tambah Target
            </BaseButton>
          </c-flex>
        </TestFieldArray>

        <c-flex
          margin="auto"
          max-width="600px"
          mt="28px"
          justify-content="space-between"
          gap="20px"
        >
          <BaseButton
            size="large"
            border-radius="1000px"
            width="100%"
            variant="outlined"
            :disabled="isSubmitting"
            @click="$router.back()"
          >
            Batal
          </BaseButton>
          <BaseButton
            size="large"
            border-radius="1000px"
            width="100%"
            :disabled="$v.$invalid || !$v.$anyDirty"
            :is-loading="isSubmitting"
            @click="isOpenModalConfirmationEditSchema = true"
          >
            {{ isEditPage ? 'Simpan' : 'Tambah' }}
          </BaseButton>
        </c-flex>
      </c-box>
    </c-box>

    <!-- MODAL KONFIRMASI EDIT SKEMA-->
    <BaseModal
      :is-open="!!isOpenModalConfirmationEditSchema"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            width="150px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          py="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="20px"
            color="primary.400"
          >
            Apakah kamu yakin ingin {{ isEditPage ? 'merubah' : 'menambahkan' }} skema?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <c-flex gap="8px">
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="isOpenModalConfirmationEditSchema = false"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              :is-loading="isSubmitting"
              @click="onSubmit"
            >
              Simpan
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>

    <!-- MODAL ERROR GENERAL-->
    <BaseModal
      :is-open="!!isOpenModalError"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            width="150px"
            object-fit="cover"
            :src="require('@/assets/images/image-warning.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          py="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            margin-top="8px"
            size-mobile="20px"
            size-desktop="28px"
            color="primary.400"
          >
            Gagal Menyimpan Skema
          </BaseText>
          <BaseText
            margin-top="16px"
            size-mobile="16px"
            size-desktop="18px"
            color="primary.400"
            v-html="errorMessage"
          />
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <c-flex>
            <BaseButton
              color="primary"
              rounded="1000px"
              width="100%"
              @click="isOpenModalError = false"
            >
              Oke
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import generalMixin from '@/utils/general-mixins'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import BaseText from '@/components/elements/base-text.vue'
import { CBox, CFlex, CImage, CText, CCheckbox, CButton } from '@chakra-ui/vue'
import BaseInputDate from '@/components/elements/base-input-date.vue'
import BaseButton from '@/components/elements/base-button.vue'
import BaseInputText from '@/components/elements/base-input-text.vue'
import { required, minValue, requiredIf } from 'vuelidate/lib/validators'
import { isInvalidField } from '@/utils/vuelidate-helpers/is-invalid-field'
import { parseErrors } from '@/utils/vuelidate-helpers/parse-errors'
import BaseInputPhotos from '@/components/elements/base-input-photos.vue'
import { isDataEmpty } from '@/utils/is-data-empty'
import { reqReferral } from '@/requests/dietela-api/referral'
import imageCompression from 'browser-image-compression'
import { parseErrorCatch } from '@/utils/parse-error-catch'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseDivider from '@/components/elements/base-divider.vue'
import BaseInputSelect from '@/components/elements/base-input-select.vue'
import TestFieldArray from '@/views/profile/detail/test-field-array.vue'
import BaseInputCheckbox2 from '@/components/elements/base-input-checkbox-2.vue'

export default {
  name: 'ManagementCoachingChallengePage',
  components: {
    BaseInputCheckbox2,
    TestFieldArray,
    BaseInputSelect,
    BaseDivider,
    CText,
    BaseModal,
    CImage,
    CCheckbox,
    CButton,
    BaseInputPhotos,
    BaseInputText,
    BaseButton,
    BaseInputDate,
    BaseText,
    BreadcrumbPath,
    CBox,
    CFlex,
  },
  mixins: [generalMixin],
  data() {
    return {
      isSubmitting: false,
      isOpenModalConfirmationEditSchema: false,
      optionPrograms: [],

      isOpenModalError: false,
      errorMessage: '',

      images: [{}],
      status: null,
      startAt: null,
      endAt: null,
      discountType: null,
      discountValue: null,
      poinDivider: null,
      poinMultiplier: null,
      name: null,
      isUseTargets: false,
      targets: [{
        id: Date.now()?.toString(),
        target: null,
        bonus: null,
      }],
      programs: [],
    }
  },
  computed: {
    isEditPage() {
      return this.$route.name === 'admin.referrals.schema.edit'
    },
  },
  async mounted() {
    const resGetSchemaOptionPrograms = await reqReferral.getSchemaOptionPrograms(
      this.$store.getters.axios, {
        slug: ['body-goals', 'body-for-baby', 'clinicare', 'konsultasi'],
      })
    this.optionPrograms = resGetSchemaOptionPrograms?.data?.data.map((v) => ({
      label: v.name,
      value: v.id,
    })) || []

    try {
      if (this.isEditPage) {
        const res = await reqReferral.detailSchema(
          this.$store.getters.axios, {
            schemaId: this.$route.params?.id,
          })
        const data = res?.data?.data
        this.name = data?.name
        this.images = [data?.bannerUrl]
        this.status = data?.status?.toString()
        this.startAt = data?.startAt
        this.endAt = data?.endAt
        this.discountType = data?.discountType
        this.discountValue = data?.discount
        this.poinDivider = data?.poinDivider
        this.poinMultiplier = data?.poinMultiplier
        this.isUseTargets = data?.referralsMilestone?.length >= 1
        this.programs = data?.products?.map((v) => v?.id) || []
        this.targets = data?.referralsMilestone?.map((v) => {
          return {
            id: (Math.random() * 1000)?.toString(),
            target: v?.totalPoin,
            bonus: v?.extraPoin,
          }
        }) || []

      }
    } catch (e) {
      this.$toast({
        status: 'error',
        title: 'Error',
        description: parseErrorCatch(e),
        duration: 3000,
      })
    }

  },
  methods: {
    parseErrors,
    isInvalidField,
    async onSubmit() {
      // check form validity
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.isSubmitting = true

      // upload photos
      let arrPromiseImageCompress = []
      let arrPromiseUploadPhotos = []

      for (let photo of this.images || []) {
        if (isDataEmpty(photo)) {
          arrPromiseUploadPhotos.push(photo)
          continue
        }

        if (typeof photo === 'string') {
          arrPromiseUploadPhotos.push(photo)
          continue
        }

        let options = {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        }
        arrPromiseImageCompress.push(imageCompression(photo.file, options))
      }

      const compressedPhotos = await Promise.all(arrPromiseImageCompress)

      for (let compressedPhoto of compressedPhotos) {
        let formData = new FormData()
        let fileName = compressedPhoto.name
        let file = new File([compressedPhoto], fileName)
        formData.append('file', file, fileName)

        let url = reqReferral.uploadFile(
          this.$store.getters.axios,
          formData,
        ).then((r) => r.data.data?.url)
          .catch((e) => {
            this.$toast({
              status: 'error',
              title: 'Error',
              description: parseErrorCatch(e),
              duration: 3000,
            })
          })
        arrPromiseUploadPhotos.push(url)
      }

      const res = await Promise.all(arrPromiseUploadPhotos)
      const resFilterd = res?.reduce((acc, curr) => {
        if (typeof curr === 'string') {
          acc.push(curr)
        }
        return acc
      }, [])

      arrPromiseImageCompress = []
      arrPromiseUploadPhotos = []

      let payload = {
        bannerUrl: resFilterd[0],
        type: this.discountType,
        totalDiscount: this.discountValue?.toString(),
        status: +this.status,
        productIds: this.programs,
        poinDivider: +this.poinDivider,
        startAt: this.startAt,
        endAt: this.endAt,
        poinMultiplier: +this.poinMultiplier,
        referralsMilestone: this.isUseTargets ? this.targets?.map((v) => {
          return {
            totalPoin: +v.target,
            extraPoin: +v.bonus,
            label: `+${v.bonus} poin extra`,
          }
        }) : [],
      }

      if (this.isEditPage) {
        // edit gift
        reqReferral.editSchema(
          this.$store.getters.axios, {
            schemaId: this.$route.params?.id,
            ...payload,
          }).then(() => {
          this.isSubmitting = false
          this.$router.push({ name: 'admin.referrals' })
        }).catch((e) => {
          const isShowPopupError = parseErrorCatch(e) === 'Cannot Update Schema In Active Period'
          if (isShowPopupError) {
            this.isOpenModalError = true
            this.errorMessage = 'Periode skema ini sama dengan skema lain\nSilahkan melakukan perubahan dengan periode skema berbeda'
          } else {
            this.$toast({
              status: 'error',
              title: 'Error',
              description: parseErrorCatch(e),
              duration: 3000,
            })
          }
        })
      } else {
        // create gift
        reqReferral.createSchema(
          this.$store.getters.axios, payload).then(() => {
          this.$router.push({ name: 'admin.referrals' })
        }).catch((e) => {
          const isShowPopupError = parseErrorCatch(e) === 'Cannot Create Schema In Active Period'
          if (isShowPopupError) {
            this.isOpenModalError = true
            this.errorMessage = 'Periode skema ini sama dengan skema lain\nSilahkan melakukan perubahan dengan periode skema berbeda'
          } else {
            this.$toast({
              status: 'error',
              title: 'Error',
              description: parseErrorCatch(e),
              duration: 3000,
            })
          }
        }).finally(() => {
          this.isSubmitting = false
        })
      }

    },
  },
  metaInfo: {
    title: 'Manajemen Kode AjakSehat | Dietela',
  },
  validations() {
    return {
      images: {
        required: (v) => {
          const filtered = v?.reduce((acc, curr) => {
            if (!isDataEmpty(curr)) {
              acc.push(curr)
            }
            return acc
          }, [])
          return !isDataEmpty(filtered)
        },
      },
      status: {
        required,
      },
      startAt: {
        required,
      },
      endAt: {
        required,
      },
      discountType: {
        required,
      },
      discountValue: {
        required,
        minValue: minValue(1),
      },
      poinDivider: {
        required,
        minValue: minValue(1),
      },
      poinMultiplier: {
        required,
        minValue: minValue(1),
      },
      programs: {
        required,
      },
      targets: {
        $each: {
          target: {
            required: requiredIf(() => {
              return !!this.isUseTargets
            }),
            minValue: minValue(1),
            isGreaterThanBefore: (_, currValue) => {
              const currIndex = this.$v.targets?.$model?.findIndex((v) => v.id === currValue?.id)
              if (currIndex === 0) {
                return true
              }
              const before = this.$v.targets?.$model[currIndex - 1]
              return +currValue?.target > +before?.target
            },
          },
          bonus: {
            required: requiredIf(() => {
              return !!this.isUseTargets
            }),
            minValue: minValue(1),
          },
        },
      },
    }
  },
}
</script>
