var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1",
      "min-width": "0"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "align-items": "center",
      "margin-bottom": "20px",
      "gap": "48px"
    }
  }, [_c('c-button', {
    attrs: {
      "margin-top": "17.5px",
      "variant": "ghost",
      "display": "flex",
      "align-items": "center",
      "gap": "1rem"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_c('c-image', {
    attrs: {
      "w": "24px",
      "h": "24px",
      "object-fit": "cover",
      "src": require('@/assets/icon-chevron-left.svg')
    }
  }), _c('c-text', {
    attrs: {
      "font-size": "16px",
      "color": "primary.400",
      "font-weight": "500"
    }
  }, [_vm._v(" Kembali ")])], 1), _c('BreadcrumbPath', {
    attrs: {
      "paths": [{
        label: 'Manajemen Referral',
        href: '/admin/referrals'
      }, {
        label: _vm.isEditPage ? 'Edit Skema Referral' : 'Tambah Skema Referral',
        isCurrent: true
      }]
    }
  })], 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1270px",
      "background-color": "#FFF",
      "margin-bottom": "16px",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['16px', '30px 80px'],
      "min-height": ['unset', '74vh'],
      "display": "flex",
      "flex-direction": "column",
      "align-items": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "20px",
      "size-desktop": "28px"
    }
  }, [_vm._v(" " + _vm._s(_vm.isEditPage ? 'Edit Skema AjakSehat' : 'Tambah Skema AjakSehat') + " ")]), _c('c-box', {
    attrs: {
      "as": "form",
      "margin-top": "70px",
      "width": "100%"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "primary.400"
    }
  }, [_vm._v(" Banner Skema ")]), _c('BaseDivider'), _c('BaseInputPhotos', {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      "photos": _vm.images,
      "is-invalid": _vm.isInvalidField(_vm.$v.images),
      "invalid-text": _vm.parseErrors('Banner Skema', _vm.$v.images)
    },
    on: {
      "update:photos": function updatePhotos(newValue) {
        return _vm.images = newValue;
      },
      "blur": _vm.$v.images.$touch
    }
  }), _c('BaseInputSelect', {
    attrs: {
      "label": "Status Skema",
      "options": [{
        value: '1',
        label: 'Aktif'
      }, {
        value: '0',
        label: 'Nonaktif'
      }],
      "placeholder": "Masukkan Status Skema",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.status),
      "invalid-text": _vm.parseErrors('Status Skema', _vm.$v.status),
      "full-width": "",
      "is-disabled": !!_vm.isEditPage
    },
    on: {
      "blur": _vm.$v.status.$touch
    },
    model: {
      value: _vm.status,
      callback: function callback($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  }), _c('c-box', {
    attrs: {
      "margin": "30px 0 16px 0"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "primary.400"
    }
  }, [_vm._v(" Periode Skema ")]), _c('BaseDivider')], 1), _c('c-flex', {
    attrs: {
      "gap": "32px"
    }
  }, [_c('BaseInputDate', {
    attrs: {
      "label": "Mulai Periode kode",
      "placeholder": "Masukkan Mulai Periode kode",
      "full-width": "",
      "is-required": "",
      "is-disabled": _vm.isEditPage && _vm.status === '1',
      "is-invalid": _vm.isInvalidField(_vm.$v.startAt),
      "invalid-text": _vm.parseErrors('Mulai Periode kode', _vm.$v.startAt)
    },
    on: {
      "blur": _vm.$v.startAt.$touch
    },
    scopedSlots: _vm._u([{
      key: "input-left-addon",
      fn: function fn() {
        return [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-calendar.svg'),
            "height": "24px",
            "width": "24px",
            "fill": "#888888"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.startAt,
      callback: function callback($$v) {
        _vm.startAt = $$v;
      },
      expression: "startAt"
    }
  }), _c('BaseInputDate', {
    attrs: {
      "label": "Akhir Periode kode",
      "placeholder": "Masukkan Akhir Periode kode",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.endAt),
      "invalid-text": _vm.parseErrors('Akhir Periode kode', _vm.$v.endAt)
    },
    on: {
      "blur": _vm.$v.endAt.$touch
    },
    scopedSlots: _vm._u([{
      key: "input-left-addon",
      fn: function fn() {
        return [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-calendar.svg'),
            "height": "24px",
            "width": "24px",
            "fill": "#888888"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.endAt,
      callback: function callback($$v) {
        _vm.endAt = $$v;
      },
      expression: "endAt"
    }
  })], 1), _c('BaseInputSelect', {
    attrs: {
      "label": "Jenis Potongan Kode",
      "options": [{
        value: 'fix',
        label: 'Fix'
      }, {
        value: 'percent',
        label: 'Percent'
      }],
      "placeholder": "Pilih Jenis Potongan Kode",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.discountType),
      "invalid-text": _vm.parseErrors('Jenis Potongan Kode', _vm.$v.discountType),
      "full-width": ""
    },
    on: {
      "blur": _vm.$v.discountType.$touch
    },
    model: {
      value: _vm.discountType,
      callback: function callback($$v) {
        _vm.discountType = $$v;
      },
      expression: "discountType"
    }
  }), _c('BaseInputText', {
    attrs: {
      "label": "Nilai Potongan Kode",
      "placeholder": "Masukkan Nilai Potongan Kode",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.discountValue),
      "invalid-text": _vm.parseErrors('Nilai Potongan Kode', _vm.$v.discountValue),
      "input-left-addon": _vm.discountType === 'fix' ? 'Rp' : null,
      "input-right-addon": _vm.discountType === 'percent' ? '%' : null
    },
    on: {
      "blur": _vm.$v.discountValue.$touch
    },
    model: {
      value: _vm.discountValue,
      callback: function callback($$v) {
        _vm.discountValue = $$v;
      },
      expression: "discountValue"
    }
  }), _c('c-box', {
    attrs: {
      "margin": "30px 0 16px 0"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "primary.400"
    }
  }, [_vm._v(" Program Terpilih ")]), _c('BaseDivider')], 1), _c('BaseInputCheckbox2', {
    attrs: {
      "is-required": "",
      "template-columns": "repeat(2, 1fr)",
      "options": _vm.optionPrograms,
      "is-invalid": _vm.isInvalidField(_vm.$v.programs),
      "invalid-text": _vm.parseErrors('Opsi Program', _vm.$v.programs),
      "is-inline": false,
      "gap": "16px"
    },
    on: {
      "blur": _vm.$v.programs.$touch
    },
    model: {
      value: _vm.programs,
      callback: function callback($$v) {
        _vm.programs = $$v;
      },
      expression: "programs"
    }
  }), _c('c-box', {
    attrs: {
      "margin": "30px 0 16px 0"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "primary.400"
    }
  }, [_vm._v(" Ketentuan Poin ")]), _c('BaseDivider')], 1), _c('BaseInputText', {
    attrs: {
      "label": "Minimal Nilai Transaksi",
      "placeholder": "Masukkan Minimal Nilai Transaksi",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.poinDivider),
      "invalid-text": _vm.parseErrors('Minimal Nilai Transaksi', _vm.$v.poinDivider)
    },
    on: {
      "blur": _vm.$v.poinDivider.$touch
    },
    model: {
      value: _vm.poinDivider,
      callback: function callback($$v) {
        _vm.poinDivider = $$v;
      },
      expression: "poinDivider"
    }
  }), _c('BaseInputText', {
    attrs: {
      "label": "Poin per Nilai Transaksi",
      "placeholder": "Masukkan Poin per Nilai Transaksi",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.poinMultiplier),
      "invalid-text": _vm.parseErrors('Poin per Nilai Transaksi', _vm.$v.poinMultiplier)
    },
    on: {
      "blur": _vm.$v.poinMultiplier.$touch
    },
    model: {
      value: _vm.poinMultiplier,
      callback: function callback($$v) {
        _vm.poinMultiplier = $$v;
      },
      expression: "poinMultiplier"
    }
  }), _c('c-box', {
    attrs: {
      "margin": "30px 0 16px 0"
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": "16px",
      "align-items": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "primary.400"
    }
  }, [_vm._v(" Bonus Poin ")]), _c('c-checkbox', {
    attrs: {
      "size": "lg",
      "variant-color": "primary"
    },
    model: {
      value: _vm.isUseTargets,
      callback: function callback($$v) {
        _vm.isUseTargets = $$v;
      },
      expression: "isUseTargets"
    }
  })], 1), _c('BaseDivider')], 1), _c('TestFieldArray', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var push = _ref.push,
            remove = _ref.remove;
        return [_vm._l(_vm.targets, function (v, i) {
          return _c('c-box', {
            key: i,
            attrs: {
              "margin-top": "16px",
              "_first": {
                marginTop: '0px'
              }
            }
          }, [_c('c-flex', {
            attrs: {
              "gap": "32px",
              "align-items": "center"
            }
          }, [_c('BaseInputText', {
            attrs: {
              "is-disabled": !_vm.isUseTargets,
              "label": "Target ".concat(i + 1),
              "placeholder": "Masukkan Target ".concat(i + 1),
              "full-width": "",
              "is-required": "",
              "is-invalid": _vm.isInvalidField(_vm.$v.targets.$each[i].target),
              "invalid-text": _vm.parseErrors("Target ".concat(i + 1), _vm.$v.targets.$each[i].target)
            },
            on: {
              "blur": _vm.$v.targets.$each[i].target.$touch
            },
            model: {
              value: v.target,
              callback: function callback($$v) {
                _vm.$set(v, "target", $$v);
              },
              expression: "v.target"
            }
          }), _c('BaseInputText', {
            attrs: {
              "is-disabled": !_vm.isUseTargets,
              "label": "Bonus",
              "placeholder": "Masukkan Bonus",
              "full-width": "",
              "is-required": "",
              "is-invalid": _vm.isInvalidField(_vm.$v.targets.$each[i].bonus),
              "invalid-text": _vm.parseErrors('Bonus', _vm.$v.targets.$each[i].bonus)
            },
            on: {
              "blur": _vm.$v.targets.$each[i].bonus.$touch
            },
            model: {
              value: v.bonus,
              callback: function callback($$v) {
                _vm.$set(v, "bonus", $$v);
              },
              expression: "v.bonus"
            }
          }), i !== 0 ? _c('c-button', {
            attrs: {
              "background-color": "transparent",
              "disabled": !_vm.isUseTargets
            },
            on: {
              "click": function click($event) {
                return remove(_vm.targets, i);
              }
            }
          }, [_c('inline-svg', {
            attrs: {
              "src": require('@/assets/icons/icon-trash.svg'),
              "height": "38px",
              "width": "38px",
              "fill": "#D32737"
            }
          })], 1) : _vm._e()], 1)], 1);
        }), _c('c-flex', {
          attrs: {
            "justify-content": "center"
          }
        }, [_c('BaseButton', {
          attrs: {
            "margin": "auto",
            "mt": "28px",
            "size": "large",
            "border-radius": "1000px",
            "variant": "outlined",
            "width": "70%",
            "disabled": !_vm.isUseTargets
          },
          on: {
            "click": function click($event) {
              var _Date$now;

              $event.preventDefault();
              push(_vm.targets, {
                id: (_Date$now = Date.now()) === null || _Date$now === void 0 ? void 0 : _Date$now.toString(),
                target: null,
                bonus: null
              });
            }
          }
        }, [_vm._v(" Tambah Target ")])], 1)];
      }
    }])
  }), _c('c-flex', {
    attrs: {
      "margin": "auto",
      "max-width": "600px",
      "mt": "28px",
      "justify-content": "space-between",
      "gap": "20px"
    }
  }, [_c('BaseButton', {
    attrs: {
      "size": "large",
      "border-radius": "1000px",
      "width": "100%",
      "variant": "outlined",
      "disabled": _vm.isSubmitting
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_vm._v(" Batal ")]), _c('BaseButton', {
    attrs: {
      "size": "large",
      "border-radius": "1000px",
      "width": "100%",
      "disabled": _vm.$v.$invalid || !_vm.$v.$anyDirty,
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": function click($event) {
        _vm.isOpenModalConfirmationEditSchema = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.isEditPage ? 'Simpan' : 'Tambah') + " ")])], 1)], 1)], 1), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalConfirmationEditSchema,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "width": "150px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Apakah kamu yakin ingin " + _vm._s(_vm.isEditPage ? 'merubah' : 'menambahkan') + " skema? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('c-flex', {
          attrs: {
            "gap": "8px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalConfirmationEditSchema = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isSubmitting
          },
          on: {
            "click": _vm.onSubmit
          }
        }, [_vm._v(" Simpan ")])], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalError,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "width": "150px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-warning.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "margin-top": "8px",
            "size-mobile": "20px",
            "size-desktop": "28px",
            "color": "primary.400"
          }
        }, [_vm._v(" Gagal Menyimpan Skema ")]), _c('BaseText', {
          attrs: {
            "margin-top": "16px",
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": "primary.400"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.errorMessage)
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('c-flex', [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalError = false;
            }
          }
        }, [_vm._v(" Oke ")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }